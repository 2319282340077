<template>
  <div>
    <v-row no-gutters v-if="fieldsLoading">
      <v-col v-for="n in 9" :key="n" cols="12" sm="6" lg="3">
       <v-sheet class="mt-2">
        <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
    <template v-else>
      <v-row class="mt-3 mb-2 mx-0">
        <v-col cols="12" class="pa-0 pl-1 pt-1">
          <v-autocomplete outlined dense :label="$t('message.projects.project')" :items="listOfProjects" hide-details v-model="activeProjectId" @change="setActiveProject(activeProjectId)" item-text="projectName" item-value="id"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mb-2 mx-0">
        <form-data :references.sync="formReferences" :model="projectObj" ref="projectFormRef"></form-data>
      </v-row>
       <template>
        <v-row>
          <v-col class="pa-1" v-for="(item, index) in speedDialItems" cols="6" lg="2" md="3" sm="4" xl="2" xs="2" :key="index">
            <v-card :key="index" :to="item.to" outlined rounded="lg" border variant="outlined">
              <v-card-text class="text-center">
                <template v-if="item.type === 'link'"> <a :href="item.link" target="_blank">{{ item.name }}</a></template>
                <template v-else>{{ item.name }}</template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <!--  custom_menu_position text-right -->
      <!-- <v-row class="mx-0 custom_menu_position" :style="`right: ${subMenuRightPosition}vw;`">
        <v-menu top offset-y :max-height="`${clientHeight - 150}px`" content-class="custom_bottom_up">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small v-bind="attrs" v-on="on" color="primary" dark fab :loading="customModulesLoading">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list dense style="opacity:0.86;">
            <v-list-item v-for="(item, index) in speedDialItems" :key="index" :to="item.to">
              <v-list-item-title>
                <template v-if="item.type === 'link'"> <a :href="item.link" target="_blank">{{ item.name }}</a></template>
                <template v-else>{{ item.name }}</template>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row> -->
      <!-- <v-row class="mb-2 mx-0">
        <v-speed-dial v-model="showItems" bottom left fixed class="custom-speed-dial">
          <template v-slot:activator>
            <v-btn small v-model="showItems" color="primary" dark fab :loading="customModulesLoading">
              <v-icon v-if="showItems">mdi-close</v-icon>
              <v-icon v-else>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-btn small class="text-capitalize ma-0 my-1 pa-1" min-width="80px" color="primary" v-for="item in speedDialItems" :key="`${item.name}_menuitem`" :to="item.to">{{ item.name }}</v-btn>
        </v-speed-dial>
      </v-row> -->
    </template>
  </div>
</template>

<script>
import FormTemplate from '@/components/FormTemplate'
import { mapGetters } from 'vuex'
import { hostAppApi } from '@/plugins/axios_settings'
import PermissionsMixin from '@/mixins/permissions'
import projectMixin from '@/mixins/projectMixin'
import FeaturesMixin from '@/mixins/features_list'
export default {
  mixins: [PermissionsMixin, projectMixin, FeaturesMixin],
  data () {
    return {
      projectEquipmentlist: 0,
      permissionCheckModules: ['drawing', 'ndt', 'pressure-test-report', 'weld-log', 'surface-report', 'technical-report', 'material-list', 'pwht', 'pwps', 'wps'], // project feature modules
      projectObj: {},
      listOfProjects: [],
      listOfCustomers: [],
      listOfStates: [],
      activeProjectId: 0,
      showItems: false,
      hostUserId: this.$cookie.get(process.env.VUE_APP_HOST_USER) ? parseInt(this.$cookie.get(process.env.VUE_APP_HOST_USER)) : 0,
      hostRef: this.$cookie.get('WelditPwa_host-ref'),
      speedDialItems: [],
      customModulesLoading: false,
      fieldsLoading: false,
      customModuleSpeedDialItems: [],
      deviationSpeedDialItem: null,
      clientHeight: window.innerHeight,
      warehouseMaterialSpeedDialItem: null,
      subMenuRightPosition: 1
    }
  },
  computed: {
    ...mapGetters(['formType']),
    formReferences () {
      return {
        cardFlat: true,
        cardTitleClass: 'pa-1',
        layoutClass: 'pa-1',
        properties: [{
          model: 'customer_id',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          items: this.listOfCustomers,
          select_text: 'name',
          select_value: 'id',
          disabled: true,
          label: this.$t('message.projects.customer'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'state_id',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          items: this.listOfStates,
          select_text: this.$i18n.locale === 'en' ? 'en_name' : 'no_name',
          select_value: 'id',
          disabled: true,
          label: this.$t('message.projects.status'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'description',
          type: this.formType.TEXTAREA,
          rules: [],
          label: this.$t('message.deviation.description'),
          class: 'xs12',
          rows: 3,
          disabled: true
        }],
        buttons: [{
          name: 'back',
          color: 'info',
          click: () => this.$router.go(-1), // this.$router.push('/projects'),
          label: this.$t('message.login.back'),
          to: '/projects',
          is_show: true
        }]
      }
    },
    customStyle () {
      return 'position:fixed;'
    }
  },
  created () {
    this.getAllProjects()
    this.getCustomers()
    this.getStates()
    if (this.$route.params.id) {
      this.setActiveProject(this.$route.params.id)
      this.activeProjectId = parseInt(this.$route.params.id)
    }
    /* On Resize */
    window.addEventListener('resize', this.windowResizeHandler)
  },
  mounted () {
    this.getCustomModules()
    this.$eventBus.$on('languageChanged', (locale) => {
      this.getCustomModules()
    })
    this.getSubMenuItemRightPosition()
    // this.setPermission()
  },
  methods: {
    // setPermission () {
    //   this.$i18n.locale = window.localStorage.getItem(process.env.VUE_APP_LOCALE_STORAGE) ? window.localStorage.getItem(process.env.VUE_APP_LOCALE_STORAGE) : 'no'
    //   const authToken = this.$cookie.get(process.env.VUE_APP_TOKEN)
    //   const user = this.$cookie.get(process.env.VUE_APP_USER) ? JSON.parse(this.$cookie.get(process.env.VUE_APP_USER)) : {}
    //   const authDetails = { authToken, user }
    //   if (authToken) {
    //     hostAppApi.get(`https://${this.hostRef}/api/check_and_get_tenant_features?pwa_project_id=${this.$route.params ? this.$route.params.id : ''}`, { headers: { language: this.$i18n.locale } })
    //       .then((response) => {
    //         if (response && response.data) {
    //           if (response.data.features) {
    //             window.localStorage.removeItem(process.env.VUE_APP_FEATURES_LIST)
    //             window.localStorage.setItem('UserRole', JSON.stringify(response.data.role))
    //             const features = response.data.features
    //             if (features.timer.enabled) {
    //               setTimeout(() => {
    //                 window.localStorage.setItem(process.env.VUE_APP_FEATURES_LIST, this.$formatter.encryptString(JSON.stringify(features)))
    //               }, 0)
    //             } else {
    //               this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.common.timerFeatureDisabled' })
    //               this.$store.dispatch('logout')
    //               this.$router.push('/login').catch(() => {})
    //             }
    //           }
    //           if (response.data.permissions && response.data.role) {
    //             /* set user permission and roles  */
    //             var date = new Date()
    //             date.setDate(date.getDate() + 4)
    //             const obj = JSON.stringify({ permissions: response.data.permissions || {}, role: response.data.role || {} })
    //             if (obj) {
    //               this.$cookie.set(process.env.VUE_APP_PERMISSIONS_AND_ROLES, this.$formatter.encryptString(obj), { expires: date })
    //               this.$store.commit('setPermissions', JSON.parse(obj))
    //             }
    //           }
    //         }
    //       })
    //     this.$store.commit('setAuth', authDetails)
    //   }
    //   /* set permission and roles in store-state variables */
    //   let permissionsAndRoles = this.$cookie.get(process.env.VUE_APP_PERMISSIONS_AND_ROLES)
    //   if (permissionsAndRoles) {
    //     permissionsAndRoles = JSON.parse(this.$formatter.decryptString(permissionsAndRoles))
    //     if (permissionsAndRoles) this.$store.commit('setPermissions', permissionsAndRoles)
    //   }
    // },
    setSpeedDialItems () {
      // default speed dial items
      this.speedDialItems = []
      const calendarHours = {
        name: this.$t('message.timer.hours'),
        icon: 'mdi-plus',
        to: `/calendar/actions?project_id=${this.activeProjectId}`
      }
      const projectFiles = {
        name: this.$t('message.layout.files'),
        icon: 'mdi-file',
        to: `/files?project_id:${this.activeProjectId}`
      }
      if (this.checkFeatureEnabled('calendar')) this.speedDialItems.push(calendarHours)
      if (this.checkFeatureEnabled('files')) this.speedDialItems.push(projectFiles)

      /* add speed dial item dynamic values */
      const projectCheckList = {
        name: this.$t('message.checklist.title'),
        icon: 'mdi-book-plus',
        to: `/projects/${this.activeProjectId}/checklists`
      }
      const projectEquipment = {
        name: this.$t('message.equipments.equipment'),
        icon: 'mdi-book-plus',
        to: `/projects/${this.activeProjectId}/equipments`
      }
      this.speedDialItems.push(projectCheckList) // if (this.checkFeatureEnabled('checklists')) this.speedDialItems.push(projectCheckList)
      if (this.checkFeatureEnabled('equipments') && this.checkActionPermission(this.CONSTANTS.projectequipment, this.CONSTANTS.LIST) && this.projectEquipmentlist) this.speedDialItems.push(projectEquipment)
      // check if deviation speeddialItem has then add
      if (this.deviationSpeedDialItem && this.checkFeatureEnabled('deviations')) this.speedDialItems.push(this.deviationSpeedDialItem)
      // check permission if has custom modules list permission then show add in the menu list
      if (!this.checkActionPermission(this.CONSTANTS.custommodule, this.CONSTANTS.LIST)) this.customModuleSpeedDialItems = this.customModuleSpeedDialItems.filter(x => x.type !== 'module')
      this.speedDialItems.push.apply(this.speedDialItems, this.customModuleSpeedDialItems)
      // if (this.checkActionPermission(this.CONSTANTS.custommodule, this.CONSTANTS.LIST)) this.speedDialItems.push.apply(this.speedDialItems, this.customModuleSpeedDialItems)
      // module report speed dial
      const moduleReport = {
        name: this.$t('message.moduleReports.title'),
        icon: 'mdi-book-plus',
        to: `/projects/${this.activeProjectId}/module_report`
      }
      this.speedDialItems.push(moduleReport)
      if (this.warehouseMaterialSpeedDialItem && this.checkFeatureEnabled('warehouse')) this.speedDialItems.push(this.warehouseMaterialSpeedDialItem)
    },
    setActiveProject (id) {
      this.fieldsLoading = true
      this.$api.execute('get', `projects/${id}`).then((response) => {
        if (response.data) {
          this.projectObj = this.$formatter.cloneVariable(response.data)
          this.getCustomModules()
        }
      }) // .finally(() => { this.fieldsLoading = false })
    },
    getAllProjects () {
      this.getSetListItems(`projects/get_as_simplified_for_pwa?filter=${this.isAdmin() ? 3 : 1}`, 'listOfProjects') // 4 is all, 2 is myall
    },
    getCustomers () {
      this.getSetListItems('customers', 'listOfCustomers')
    },
    getStates () {
      this.getSetListItems('projectstates', 'listOfStates')
    },
    getSetListItems (url, listName) {
      this.$api.execute('get', url).then((response) => {
        if (response.data) {
          if (listName === 'listOfProjects') {
            const list = this.$formatter.cloneVariable(response.data)
            const timerProject = list.find(x => x.number === 'TM001')
            if (timerProject) {
              const indexOf = list.indexOf(timerProject)
              list.splice(indexOf, 1)
            }
            list.forEach(project => {
              project.projectName = this.setProjectname(project)
            })
            this[listName] = list
          } else this[listName] = response.data
        }
      })
    },
    getCustomModules () {
      this.customModulesLoading = true
      this.deviationSpeedDialItem = null
      this.warehouseMaterialSpeedDialItem = null
      hostAppApi.get(`https://${this.hostRef}/api/get_project_modules_list?project_id=${this.activeProjectId}&user_id=${this.hostUserId}`, { headers: { language: this.$i18n.locale } })
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const list = this.$formatter.cloneVariable(response.data)
            const customModules = []
            list.map((customModule) => {
              if (customModule.tab === 'equipment') {
                this.projectEquipmentlist = 1
              }
              const obj = { name: customModule.type === 'link' ? 'SharePoint' : customModule.displayName }
              if (customModule.type === 'feature') {
                if (customModule.tab === 'deviation' && this.checkActionPermission(this.CONSTANTS.projectdeviation, this.CONSTANTS.LIST)) {
                  obj.to = `/projects/${this.activeProjectId}/deviations`
                  this.deviationSpeedDialItem = obj
                } else if (customModule.tab === 'warehouse-material') {
                  obj.to = `/projects/${this.activeProjectId}/materials`
                  this.warehouseMaterialSpeedDialItem = obj
                } else if (customModule.type === 'feature' && this.permissionCheckModules.includes(customModule.tab) && this.checkActionPermission(this.CONSTANTS[customModule.tab], this.CONSTANTS.LIST)) {
                  obj.to = `/projects/${this.activeProjectId}/${customModule.tab}`
                  customModules.push(obj)
                }
              } else if (customModule.type === 'module') {
                obj.to = `/projects/${this.activeProjectId}/custom_modules/${customModule.id}?name=${customModule.displayName || customModule.name || ''}`
                customModules.push(obj)
              } else if (customModule.type === 'link') {
                obj.link = customModule.link
                obj.type = 'link'
                customModules.push(obj)
              }
            })
            this.customModuleSpeedDialItems = customModules
          }
        }).finally(() => {
          this.setSpeedDialItems()
          this.customModulesLoading = false
          this.fieldsLoading = false
        })
    },
    windowResizeHandler () {
      this.clientHeight = window.innerHeight
      this.getSubMenuItemRightPosition()
    },
    getSubMenuItemRightPosition () {
      if (window.innerWidth > 1264) {
        this.subMenuRightPosition = this.pixelToVW((window.innerWidth - 1264))
      } else this.subMenuRightPosition = 1
    },
    pixelToVW (value) {
      return ((51 * value) / window.innerWidth).toFixed(2)
    }
  },
  components: {
    'form-data': FormTemplate
  },
  beforeDestroy () {
    this.$eventBus.$off('languageChanged')
  },
  destroyed () {
    window.removeEventListener('resize', this.windowResizeHandler)
  }
}
</script>

<style>
.custom-speed-dial div.v-speed-dial__list {
  align-items: baseline !important;
}
.v-speed-dial--bottom {
  bottom: 10vh !important;
}
.custom_menu_position {
  position: fixed;
  bottom: 10vh !important;
}
@media screen and (min-height:300px) and (max-height:349px) and (orientation:landscape) {
  .custom_menu_position {
    bottom: 20vh !important;
  }
}

@media screen and (min-height:350px) and (max-height:399px) and (orientation:landscape) {
  .custom_menu_position {
    bottom: 18vh !important;
  }
}

@media screen and (min-height:400px) and (max-height:449px) and (orientation:landscape) {
  .custom_menu_position {
    bottom: 16vh !important;
  }
}

@media screen and (min-height:450px) and (max-height:499px) and (orientation:landscape) {
  .custom_menu_position {
    bottom: 14vh !important;
  }
}
@media screen and (min-height:500px) and (max-height:569px) and (orientation:landscape) {
  .custom_menu_position {
    bottom: 12vh !important;
  }
}
/* .custom_bottom_up {
  bottom: 10vh !important;
} */
</style>
